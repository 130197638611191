import React from 'react';
import styled from 'styled-components';
import useScriptDutchie from '../../hooks/useScriptDutchie';

const LiveMenu = () => {

    const Header = styled.div`
        background-color: #f3f6f8;
        padding: 30px 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 200px;
        h1{
            color: black;
        }
    `;

  const FullScreen = styled.div`
    background-color: #f3f6f8;
    width: 100%;
    overflow: hidden;
  `;

  return (
    <>
      <Header><h1>CAMBRDIGE MENU</h1></Header>
      <FullScreen>
        <div id="dutchie--embed__script" />
        {useScriptDutchie('cambridge')}
      </FullScreen>
    </>
  );
};

export default LiveMenu;
